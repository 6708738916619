(function () {
    'use strict';

    window.invokePreload = window.invokePreload || {};

    invokePreload.onLoad = function (link) {
        link.setAttribute("preloaded", "true");
        link.removeAttribute("onload");
    };

    invokePreload.onScriptLoad = function (link, error) {
        link.setAttribute("preloaded", !!error ? "error" : "true");
        link.removeAttribute("onload");
        link.removeAttribute("onerror");
    };

    invokePreload.onScriptError = function (link) {
        invokePreload.onScriptLoad(link, true);
    };

    invokePreload.onStyleLoad = function (link) {
        if ([].map.call(document.styleSheets, function (stylesheet) {
                return stylesheet.href;
            }).indexOf(link.href) === -1) {
            link.setAttribute("rel", "stylesheet");
            link.setAttribute("type", "text/css");
            link.setAttribute("media", "all");
            link.setAttribute("preloaded", "true");
            link.removeAttribute("as");
        }

        link.removeAttribute("onload");
    };

}());
(function () {
    'use strict';

    var processScript = function processScript(link, isAsync, resolve) {
        var script = document.createElement("script");
        script.async = isAsync;
        script.onload = resolve;
        script.onerror = resolve;
        script.setAttribute("src", link.href);

        if (link.integrity) {
            script.integrity = link.integrity;
        }

        if (link.insertAdjacentElement) {
            link.insertAdjacentElement("afterend", script);
        } else {
            link.parentNode.appendChild(script);
        }

        return script;
    };

    var checkEs6 = function checkEs6() {
        try {
            new Function("(a = 0) => a");
            return true;
        } catch (e) {
            return false;
        }
    };

    var ES6 = checkEs6();
    /**
     * this skips type="module" for browsers that dont understand es6
     * and skips nomodule for browsers that understand es6
     */

    var skipNonMatchingModules = function skipNonMatchingModules(element) {
        if ((element.getAttribute("as") === "script" || element.getAttribute("as") === "worker") && (element.getAttribute("rel") === "nomodule" || element.hasAttribute("module"))) {
            //check for type="module" / nomodule (load es6 or es5) depending on browser capabilties
            var nm = element.getAttribute("rel") === "nomodule";
            var m = element.hasAttribute("module");

            if (m && !ES6 || nm && ES6) {
                return true;
            }
        }

        return false;
    };
    var getPreloads = function getPreloads(selector) {
        var preloads = document.querySelectorAll(selector);
        var uniquePreloads = [],
            seenUrls = [];

        for (var i = 0, len = preloads.length; i < len; ++i) {
            var preload = preloads[i];

            if (seenUrls.indexOf(preload.href) === -1) {
                seenUrls.push(preload.href);
                uniquePreloads.push(preload);
            }
        }

        return uniquePreloads;
    };

    var setNonCriticalAsync = true;

    var processLink = function processLink(link, isAsync, resolve) {
        if (link.getAttribute("preloaded") === "true") {
            processScript(link, isAsync, resolve);
        } else {
            setTimeout(function () {
                processLink(link, isAsync, resolve);
            }, 10);
        }
    };

    var invokeCriticalLinkResources = function invokeCriticalLinkResources(preloads) {
        var promises = [];

        while (preloads.length) {
            promises.push(new Promise(function (resolve) {
                processLink(preloads.shift(), false, resolve);
            }));
        }

        return Promise.all(promises);
    };

    var invokeNonCriticalLinkResources = function invokeNonCriticalLinkResources(preloads) {
        var promises = [];

        while (preloads.length) {
            promises.push(new Promise(function (resolve) {
                processLink(preloads.shift(), setNonCriticalAsync, resolve);
            }));
        }

        return Promise.all(promises);
    };

    var perfLog = function perfLog() {
        if (window.performance && window.performance.now) {}
    };

    var invokePreloads = function invokePreloads() {
        perfLog();
        var preloads = getPreloads("link[rel='preload'][as='script']");
        var preload,
            criticals = [],
            noncriticals = [];

        while ((preload = preloads.shift()) !== undefined) {
            if (!skipNonMatchingModules(preload)) {
                if (preload.hasAttribute("critical")) {
                    criticals.push(preload);
                } else {
                    noncriticals.push(preload);
                }
            }
        }

        setNonCriticalAsync = criticals.length === 0;
        // first comes the criticals
        invokeCriticalLinkResources(criticals).then(function () {
            return invokeNonCriticalLinkResources(noncriticals);
        }).then(function () {
            document.dispatchEvent(new CustomEvent("AllScriptsExecuted"));
            perfLog();
        });
    };

    document.addEventListener("DOMContentLoaded", invokePreloads);

}());
var preload_polyfill = (function () {
    'use strict';

    var activateStylesheet = function activateStylesheet(link) {
        link.removeAttribute("as");
        link.setAttribute("media", "all");
    };

    var processCss = function processCss(link) {
        if (window.requestAnimationFrame) {
            window.requestAnimationFrame(function () {
                return activateStylesheet(link);
            });
        } else {
            activateStylesheet(link);
        }

        return link;
    };

    var checkEs6 = function checkEs6() {
        try {
            new Function("(a = 0) => a");
            return true;
        } catch (e) {
            return false;
        }
    };

    var ES6 = checkEs6();
    /**
     * this skips type="module" for browsers that dont understand es6
     * and skips nomodule for browsers that understand es6
     */

    var skipNonMatchingModules = function skipNonMatchingModules(element) {
        if ((element.getAttribute("as") === "script" || element.getAttribute("as") === "worker") && (element.getAttribute("rel") === "nomodule" || element.hasAttribute("module"))) {
            //check for type="module" / nomodule (load es6 or es5) depending on browser capabilties
            var nm = element.getAttribute("rel") === "nomodule";
            var m = element.hasAttribute("module");

            if (m && !ES6 || nm && ES6) {
                return true;
            }
        }

        return false;
    };
    var getPreloads = function getPreloads(selector) {
        var preloads = document.querySelectorAll(selector);
        var uniquePreloads = [],
            seenUrls = [];

        for (var i = 0, len = preloads.length; i < len; ++i) {
            var preload = preloads[i];

            if (seenUrls.indexOf(preload.href) === -1) {
                seenUrls.push(preload.href);
                uniquePreloads.push(preload);
            }
        }

        return uniquePreloads;
    };

    var setLoaded = function setLoaded(element) {
        var error = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
        element.setAttribute("preloaded", error ? "error" : true);
        element.removeEventListener("load", onLoad);
        element.removeAttribute("onload");
        element.onload = null;
    };
    /**
     * called when a preload is loaded
     */


    var onLoad = function onLoad(event, element) {
        //immediate invoke css
        if (element.getAttribute("as") === "style") {
            setLoaded(element);
            processCss(element);
            return;
        }

        setLoaded(element);
        element.dispatchEvent(new CustomEvent("load", event));
    };

    var onError = function onError(event, element) {
        setLoaded(element, true);
    };

    var loadWithFetch = function loadWithFetch(element) {
        var options = {
            method: "GET",
            mode: "cors",
            cache: "force-cache"
        };
        fetch(element.href, options).then(function (response) {
            if (response.ok) {
                onLoad(null, element);
            } else {
                onError(null, element);
            }
        }).catch(function () {
            return onError(null, element);
        });
    };
    /**
     * load preload with non-blocking xhr
     */


    var loadWithXhr = function loadWithXhr(element) {
        if (window.fetch) {
            return loadWithFetch(element);
        }

        var request = new XMLHttpRequest();
        request.addEventListener("load", function (event) {
            if (request.status >= 200 && request.status < 300) {
                onLoad(event, element);
            } else {
                onError(event, element);
            }
        });
        request.open("GET", element.href, true);
        request.send();
    };

    var loadImage = function loadImage(element) {
        var img = new Image();

        img.onload = function (event) {
            return onLoad(event, element);
        };

        img.onerror = function (event) {
            return onError(event, element);
        };

        img.src = element.href;
    };

    var loadStyle = function loadStyle(element) {
        element.onload = function (event) {
            return onLoad(event, element);
        };

        element.onerror = function (event) {
            return onError(event, element);
        };

        element.media = "none";
        element.type = "text/css";
        element.rel = "stylesheet";
    };

    var loadFont = function loadFont(element) {
        if (!document.fonts) {
            return loadWithXhr(element);
        } //TODO adding ttf ... to fontfaceset


        if (!element.hasAttribute("name")) {
            return loadWithXhr(element);
        }

        var f = new FontFace(element.getAttribute("name"), "url(".concat(element.href, ")"), {
            weight: element.getAttribute("weight") || "normal",
            style: "normal"
        });
        f.load(element.href).then(function (loadedFace) {
            document.fonts.add(loadedFace);
            onLoad(null, element);
        }).catch(function () {});
    };

    var loadScript = function loadScript(element) {
        if (element.getAttribute("rel") === "nomodule") {
            element.setAttribute("rel", "preload");
        }

        return loadWithXhr(element);
    };

    var load = function load(element) {
        switch (element.getAttribute("as")) {
            case "script":
                loadScript(element);
                break;

            case "image":
                loadImage(element);
                break;

            case "style":
                loadStyle(element);
                break;

            case "font":
                loadFont(element);
                break;

            default:
                loadWithXhr(element);
        }
    };

    var processed = [];
    /**
     * filters all [rel="preload"] from actual mutations and invokes "preloadLinkByElement"
     */

    var preloadLinkByMutation = function preloadLinkByMutation(mutations) {
        for (var i = 0, len = mutations.length; i < len; i++) {
            var addedNodes = mutations[i].addedNodes;

            for (var j = 0, _len = addedNodes.length; j < _len; j++) {
                var element = addedNodes[j];

                if (element.nodeName === "LINK" && element.hasAttribute("rel") && (element.getAttribute("rel") === "preload" || element.getAttribute("rel") === "nomodule")) {
                    preloadLinkByElement(element);
                }
            }
        }
    };
    /**
     * do the background fetching for a [rel="preload"]
     */


    var preloadLinkByElement = function preloadLinkByElement(element) {
        if (processed.indexOf(element.href) !== -1) {
            return;
        }

        if (skipNonMatchingModules(element)) {
            return;
        }

        load(element);
        processed.push(element.href);
    };
    /**
     * watch for preload elements to come after loading this script
     */


    var observeMutations = function observeMutations() {
        var selector = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 'link[rel="preload"]';

        // preload link[rel="preload"] by mutation
        if (window.MutationObserver) {
            var observer = new MutationObserver(function (mutations) {
                return preloadLinkByMutation(mutations);
            }).observe(document.documentElement, {
                childList: true,
                subtree: true
            });
            document.addEventListener("DOMContentLoaded", function () {
                if (observer) {
                    observer.disconnect();
                }
            });
        } else {
            var searchInterval = setInterval(function () {
                if (document.readyState == "complete") {
                    clearInterval(searchInterval);
                    scanPreloads(selector);
                }
            }, 50);
        }
    };
    /**
     * scan and preload resources
     */


    var scanPreloads = function scanPreloads() {
        var selector = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 'link[rel="preload"]';
        // preload link[rel="preload"] by selector
        var preloads = getPreloads(selector);
        var link;

        while ((link = preloads.shift()) !== undefined) {
            preloadLinkByElement(link);
        }
    };
    var polyfill = function polyfill(selector) {
        scanPreloads(selector);
        observeMutations(selector);
    };

    /**
     * entrypoint, also binds DOMContentLoaded to the invocation of preloaded scripts
     */

    var preloadPolyfill = function preloadPolyfill() {
        try {
            if (!document.createElement("link").relList.supports("preload")) {
                throw Error;
            }
        } catch (error) {
            polyfill('link[rel="preload"]');
        }
    };

    var polyfill$1 = preloadPolyfill();

    return polyfill$1;

}());

/* Font Face Observer v2.0.13 - © Bram Stein. License: BSD-3-Clause */(function(){'use strict';var f,g=[];function l(a){g.push(a);1==g.length&&f()}function m(){for(;g.length;)g[0](),g.shift()}f=function(){setTimeout(m)};function n(a){this.a=p;this.b=void 0;this.f=[];var b=this;try{a(function(a){q(b,a)},function(a){r(b,a)})}catch(c){r(b,c)}}var p=2;function t(a){return new n(function(b,c){c(a)})}function u(a){return new n(function(b){b(a)})}function q(a,b){if(a.a==p){if(b==a)throw new TypeError;var c=!1;try{var d=b&&b.then;if(null!=b&&"object"==typeof b&&"function"==typeof d){d.call(b,function(b){c||q(a,b);c=!0},function(b){c||r(a,b);c=!0});return}}catch(e){c||r(a,e);return}a.a=0;a.b=b;v(a)}}
    function r(a,b){if(a.a==p){if(b==a)throw new TypeError;a.a=1;a.b=b;v(a)}}function v(a){l(function(){if(a.a!=p)for(;a.f.length;){var b=a.f.shift(),c=b[0],d=b[1],e=b[2],b=b[3];try{0==a.a?"function"==typeof c?e(c.call(void 0,a.b)):e(a.b):1==a.a&&("function"==typeof d?e(d.call(void 0,a.b)):b(a.b))}catch(h){b(h)}}})}n.prototype.g=function(a){return this.c(void 0,a)};n.prototype.c=function(a,b){var c=this;return new n(function(d,e){c.f.push([a,b,d,e]);v(c)})};
    function w(a){return new n(function(b,c){function d(c){return function(d){h[c]=d;e+=1;e==a.length&&b(h)}}var e=0,h=[];0==a.length&&b(h);for(var k=0;k<a.length;k+=1)u(a[k]).c(d(k),c)})}function x(a){return new n(function(b,c){for(var d=0;d<a.length;d+=1)u(a[d]).c(b,c)})};window.Promise||(window.Promise=n,window.Promise.resolve=u,window.Promise.reject=t,window.Promise.race=x,window.Promise.all=w,window.Promise.prototype.then=n.prototype.c,window.Promise.prototype["catch"]=n.prototype.g);}());

(function(){function l(a,b){document.addEventListener?a.addEventListener("scroll",b,!1):a.attachEvent("scroll",b)}function m(a){document.body?a():document.addEventListener?document.addEventListener("DOMContentLoaded",function c(){document.removeEventListener("DOMContentLoaded",c);a()}):document.attachEvent("onreadystatechange",function k(){if("interactive"==document.readyState||"complete"==document.readyState)document.detachEvent("onreadystatechange",k),a()})};function r(a){this.a=document.createElement("div");this.a.setAttribute("aria-hidden","true");this.a.appendChild(document.createTextNode(a));this.b=document.createElement("span");this.c=document.createElement("span");this.h=document.createElement("span");this.f=document.createElement("span");this.g=-1;this.b.style.cssText="max-width:none;display:inline-block;position:absolute;height:100%;width:100%;overflow:scroll;font-size:16px;";this.c.style.cssText="max-width:none;display:inline-block;position:absolute;height:100%;width:100%;overflow:scroll;font-size:16px;";
    this.f.style.cssText="max-width:none;display:inline-block;position:absolute;height:100%;width:100%;overflow:scroll;font-size:16px;";this.h.style.cssText="display:inline-block;width:200%;height:200%;font-size:16px;max-width:none;";this.b.appendChild(this.h);this.c.appendChild(this.f);this.a.appendChild(this.b);this.a.appendChild(this.c)}
    function t(a,b){a.a.style.cssText="max-width:none;min-width:20px;min-height:20px;display:inline-block;overflow:hidden;position:absolute;width:auto;margin:0;padding:0;top:-999px;white-space:nowrap;font-synthesis:none;font:"+b+";"}function y(a){var b=a.a.offsetWidth,c=b+100;a.f.style.width=c+"px";a.c.scrollLeft=c;a.b.scrollLeft=a.b.scrollWidth+100;return a.g!==b?(a.g=b,!0):!1}function z(a,b){function c(){var a=k;y(a)&&a.a.parentNode&&b(a.g)}var k=a;l(a.b,c);l(a.c,c);y(a)};function A(a,b){var c=b||{};this.family=a;this.style=c.style||"normal";this.weight=c.weight||"normal";this.stretch=c.stretch||"normal"}var B=null,C=null,E=null,F=null;function G(){if(null===C)if(J()&&/Apple/.test(window.navigator.vendor)){var a=/AppleWebKit\/([0-9]+)(?:\.([0-9]+))(?:\.([0-9]+))/.exec(window.navigator.userAgent);C=!!a&&603>parseInt(a[1],10)}else C=!1;return C}function J(){null===F&&(F=!!document.fonts);return F}
    function K(){if(null===E){var a=document.createElement("div");try{a.style.font="condensed 100px sans-serif"}catch(b){}E=""!==a.style.font}return E}function L(a,b){return[a.style,a.weight,K()?a.stretch:"","100px",b].join(" ")}
    A.prototype.load=function(a,b){var c=this,k=a||"BESbswy",q=0,D=b||3E3,H=(new Date).getTime();return new Promise(function(a,b){if(J()&&!G()){var M=new Promise(function(a,b){function e(){(new Date).getTime()-H>=D?b():document.fonts.load(L(c,'"'+c.family+'"'),k).then(function(c){1<=c.length?a():setTimeout(e,25)},function(){b()})}e()}),N=new Promise(function(a,c){q=setTimeout(c,D)});Promise.race([N,M]).then(function(){clearTimeout(q);a(c)},function(){b(c)})}else m(function(){function u(){var b;if(b=-1!=
        f&&-1!=g||-1!=f&&-1!=h||-1!=g&&-1!=h)(b=f!=g&&f!=h&&g!=h)||(null===B&&(b=/AppleWebKit\/([0-9]+)(?:\.([0-9]+))/.exec(window.navigator.userAgent),B=!!b&&(536>parseInt(b[1],10)||536===parseInt(b[1],10)&&11>=parseInt(b[2],10))),b=B&&(f==v&&g==v&&h==v||f==w&&g==w&&h==w||f==x&&g==x&&h==x)),b=!b;b&&(d.parentNode&&d.parentNode.removeChild(d),clearTimeout(q),a(c))}function I(){if((new Date).getTime()-H>=D)d.parentNode&&d.parentNode.removeChild(d),b(c);else{var a=document.hidden;if(!0===a||void 0===a)f=e.a.offsetWidth,
        g=n.a.offsetWidth,h=p.a.offsetWidth,u();q=setTimeout(I,50)}}var e=new r(k),n=new r(k),p=new r(k),f=-1,g=-1,h=-1,v=-1,w=-1,x=-1,d=document.createElement("div");d.dir="ltr";t(e,L(c,"sans-serif"));t(n,L(c,"serif"));t(p,L(c,"monospace"));d.appendChild(e.a);d.appendChild(n.a);d.appendChild(p.a);document.body.appendChild(d);v=e.a.offsetWidth;w=n.a.offsetWidth;x=p.a.offsetWidth;I();z(e,function(a){f=a;u()});t(e,L(c,'"'+c.family+'",sans-serif'));z(n,function(a){g=a;u()});t(n,L(c,'"'+c.family+'",serif'));
        z(p,function(a){h=a;u()});t(p,L(c,'"'+c.family+'",monospace'))})})};"object"===typeof module?module.exports=A:(window.FontFaceObserver=A,window.FontFaceObserver.prototype.load=A.prototype.load);}());
